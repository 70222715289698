<template>
  <div>
    <!-- Filters -->
    <shared-student-filters
      :name-filter.sync="filter.name"
      :email-filter.sync="filter.email"
      @click="loadData"
    />

    <!-- Table Container Card -->
    <b-overlay
      :show="loading"
      opacity="0.10"
      rounded="sm"
    >
      <b-card
        no-body
        class="mb-0"
      >
        <b-table
          responsive
          :items="items"
          :fields="fields"
          class="mb-0"
        >

          <!-- Column: id -->
          <template #cell(id)="data">
            <span
              class="align-middle ml-50"
              v-text="data.item.count"
            />
          </template>

          <!-- Column: position -->
          <template #cell(position)="data">
            <span
              v-if="data.item.position !== null"
              class="align-middle ml-50"
              v-text="data.item.position.name_ko"
            />
          </template>

          <!-- Column: job -->
          <template #cell(job)="data">
            <span
              v-if="data.item.job !== null"
              class="align-middle ml-50"
              v-text="data.item.job.name_ko"
            />
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item
                @click="editAdminModal(data)"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">{{ $t('edit') }}</span>
              </b-dropdown-item>
              <b-dropdown-item
                @click="setupLevel(data)"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">{{ $t('setup_level') }}</span>
              </b-dropdown-item>
              <b-dropdown-item
                @click="setupTime(data)"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">{{ $t('setup_time') }}</span>
              </b-dropdown-item>
              <b-dropdown-item
                @click="deleteAdmin(data)"
              >
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">{{ $t('delete') }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>

        </b-table>
        <template slot="footer">
          <b-pagination
            v-model="filter.page"
            :per-page="perPage"
            hide-goto-end-buttons
            :total-rows="totalPage"
            align="center"
            @change="loadData"
          />
        </template>
      </b-card>
    </b-overlay>

  </div>
</template>

<script>

import {
  BCard,
  BTable,
  BDropdown,
  BDropdownItem,
  BPagination,
  BOverlay,
} from 'bootstrap-vue'
import Student from '@/models/Student'
import SharedStudentFilters from '../Shared/Student/Filter.vue'

export default {
  name: 'CompanyStudent',
  components: {
    SharedStudentFilters,
    BCard,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
  },
  data() {
    return {
      fields: [
        'id',
        'name',
        'email',
      ],
      items: [],
      loading: true,
      perPage: 10,
      totalPage: 0,
      filter: {
        name: typeof this.$route.query.name !== 'undefined' ? this.$route.query.name : '',
        email: typeof this.$route.query.email !== 'undefined' ? this.$route.query.email : '',
        page: typeof this.$route.query.page !== 'undefined' ? this.$route.query.page : 1,
      },
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    async loadData(page = 1) {
      this.loading = true
      const searchParams = {}
      searchParams.page = page
      Object.keys(this.filter).forEach(e => {
        if (this.filter[e] !== null && this.filter[e].length > 0) {
          searchParams[e] = this.filter[e]
        }
      })
      this.$router.replace({ query: searchParams })
      const response = await Student.list({ ...searchParams, role: 'company_admin' })
      this.items = response.data.data
      this.totalPage = response.data.meta.pagination.total
      this.perPage = response.data.meta.pagination.per_page
      this.filter.page = response.data.meta.pagination.current_page
      this.loading = false
    },
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.levels-checkbox-container .custom-checkbox, .times-checkbox-container .custom-checkbox {
  margin-bottom: 5px;
}
</style>
