import axios from '@/libs/axios'

class Student {
  static list(params) {
    return axios.post('admin/students', params)
  }

  static view(id, page) {
    return axios.post(`admin/students/view/${id}`, { page })
  }

  static schedule(id) {
    return axios.post(`admin/students/schedule/${id}`)
  }

  static updateSchedule(params) {
    return axios.post('admin/students/schedule', params)
  }

  static deleteStudyRoom(params) {
    return axios.post('admin/students/delete-study-room', params)
  }
}

export default Student
