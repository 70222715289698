<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        {{ $t('filters') }}
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
          md="6"
          class="mb-md-0 mb-2"
        >
          <label>{{ $t('name') }}</label>
          <b-form-input
            :value="name"
            @input="(val) => $emit('update:nameFilter', val)"
          />
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="mb-md-0 mb-2"
        >
          <label>{{ $t('email') }}</label>
          <b-form-input
            :value="email"
            @input="(val) => $emit('update:emailFilter', val)"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          class="mt-1 text-right"
        >
          <b-button
            type="submit"
            variant="outline-primary"
            @click="$emit('click')"
          >
            <feather-icon
              icon="SearchIcon"
              class="mr-50"
            />
            <span class="align-middle">{{ $t('search') }}</span>
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BFormInput, BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BFormInput,
    BButton,
  },
  props: {
    name: {
      type: [String, null],
      default: null,
    },
    email: {
      type: [String, null],
      default: null,
    },
  },
}
</script>

<style lang="scss">
</style>
